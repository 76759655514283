<section class="searchContainer">
  <div class="container">
      <div class="top-search-bar">
          <div class="widget">
            <select (change)="changeLocation($event.target.value)" class="form-control form-select" [(ngModel)]="LabLocd">
              <option *ngIf="LabLocd===''" value="" disabled selected>Select Location</option>
              <option *ngFor="let labLoc of LabLocationlist" value="{{labLoc.lab_id}}" >{{labLoc.name}} </option> 
          </select>
          </div>
          <div class="top-search-field">
              <div class="dropdown">
                  <button class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown1" aria-expanded="false">
                      <input (ngModelChange)="onSearchChange($event)" [(ngModel)]="searchKeyword" class="form-control multiselect-selected-text" type="text" placeholder="Search test name ">
                  </button> 
              </div>
          </div>
      </div>
  </div>
</section>

  
  

<div class="container rest_container">
  <div class="row">
    <div class="col-md-3 col-12"> 

      <div class="col-md-12 mb-20">
        <h6 class="mb-20 bold">Filters</h6> 
            <div class="control-group mt-4 ">
              <div class="controls">
                <label class="checkbox" for="closeButton">
                    <input id="All" type="checkbox" value="checked" (click)="filterTest('All')">   All
                </label>
              </div>
              <div class="controls">
                <label class="checkbox" for="closeButton">
                    <input id="Test" type="checkbox" value="checked" (click)="filterTest('Test')"> Test ({{dummyLabTest.length}})
                </label>
              </div>
              <div class="controls">
                <label class="checkbox" for="closeButton">
                    <input id="Packages" type="checkbox" value="checked" (click)="filterTest('Packages')">   Packages ({{LabtestisPackage.length}})
                </label>
              </div>
              <div class="controls">
                <label class="checkbox" for="closeButton">
                    <input id="Profile" type="checkbox" value="checked" (click)="filterTest('Profile')">   Profile ({{LabtestisProfile.length}}) 
                </label>
              </div>

              <br>



        

              <h6 class=" mt-20 bold">More Test  <a href="/labtest" *ngIf="FilterId!=''">Clear Filter</a> </h6>
                  <ul class="moretest">
                  <li *ngFor="let itemTagGroup of TagGroup" >
                    - <a href="/labtest-filter/{{itemTagGroup.groupname}}/{{itemTagGroup.id}}">{{itemTagGroup.groupname}}</a></li> 

                  </ul> 
                  
                  
              <h6 class=" mt-20 bold">Disorders</h6>

              <div class="lifecycle">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12" *ngIf="Disorder?.length">
                            <div class="lifecycle_Slider owl-carousel owl-theme">
                                  <div class="item" *ngFor="let item of Disorder" > 
                                    <a href="/disorder/{{item.Name}}">
                                        <div class="diseas_img">
                                            <img src="{{api.mediaURL}}{{item.Path}}">
                                        </div>
                                        <p>{{item.Name}}</p>
                                     </a>
                                  </div>  
                            </div>
                        </div>
                    </div>
                </div>
              </div>


              <a href="/disorder" class="btn btn-primary-sm">View All</a>

          </div>
          
      </div>


    </div>
    <div class="col-md-9 col-12"> 
        <div class="col-12">
          <label class="bold_lbl">{{util.translate('All Lab Test')}}</label>
        </div>
        <div class="col-lg-12">
          <div class="row product_div" *ngIf="Labtest?.length">
            <div class="pro_col col-lg-6 col-sm-12 col-md-6 col-12" *ngFor="let item of Labtest;let i = index;" > <!--  | paginate: {id:'pagin1', itemsPerPage:20, currentPage: page }(click)="goToLabtestDetail(item)" -->
              
              <div class="test_card">
                <div>
                    <div class="test_header">
                        <h5>{{item.name}} </h5>
                        <a href="#" class="">Share &nbsp;<i class="fa fa-share-alt "></i></a>
                    </div>
                    <div class="test_body">
                        <p class="" *ngIf="item.prerequisite?.length" ><i class=" fa fa-exclamation-circle pr-10"></i>Preparation required : {{item.prerequisite}}</p>
                        <p class=""><i class="fa fa-cog pr-10"></i>Parameter(s) Covered : <span>{{item.parameters_count}}</span></p>
                        <p class=""><i class="fa fa-file-text pr-10"></i>Report : <span>{{item.tat}} Hrs.</span></p>
                    </div>
                </div>
                <div class="card_footer">
                    <div class="fees">
                        <p>Fees : <span>₹ {{item.test_rate | number : '.2-2'}}/-</span></p>
                    </div>
                    <div class="footerCTA">
                        <a href="/labtest/{{getURLTxt(item.name)}}/{{item.test_id}}" class="pac_details_view"> View Detail </a>   <!-- (click)="testDetails(item.id)" -->
                        <a data-toggle="modal" data-target=".cart-success" class="btn btn-primary-sm" *ngIf="!isInCart(i)" (click)="add(i)" id="a{{i}}">Add to cart</a>
                        <a data-toggle="modal" data-target=".cart-success" class="btn btn-danger"  style="display:none;" (click)="removeQ(i)" id="r{{i}}" >Remove from cart</a>  <!-- *ngIf="isInCart(i) 'style=display:block;' :'style=display:none;' "  -->
                    </div>
                </div>
            </div>


               
            </div>
          </div>

          <div class="row product_div">
            <div class="pro_col col-lg-4 col-sm-12 col-md-6 col-xs-12" *ngFor="let item of dummy">
              <div class="pro_div hoverable">
                <div class="rest_img bg_image">
                  <ngx-skeleton-loader appearance="circle" [theme]="{ 'border-radius': '5px', height: '150px',width:'90%'}">
                  </ngx-skeleton-loader>
                </div>
                <div class="content_div">
                  <label class="pro_name">
                    <ngx-skeleton-loader appearance="line" [theme]="{  height: '20px',width:'150px'}">
                    </ngx-skeleton-loader>
                  </label>
                  <label class="addr">
                    <ngx-skeleton-loader appearance="line" [theme]="{  height: '20px',width:'120px'}">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line" [theme]="{  height: '20px',width:'70px'}"> </ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line" [theme]="{  height: '20px',width:'70px'}"> </ngx-skeleton-loader>
                  </label>


                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="has-text-centered" *ngIf="Labtest?.length ">
          <pagination-controls id="pagin1" (pageChange)="page = $event"></pagination-controls>
        </div> -->


      </div>

  </div>
</div>

<div class="modal fade cart-success" tabindex="-1" role="dialog" style="display: none;" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
     <div class="modal-content">
        
        <div class="modal-body">
           <div class="row text-center">
             <div class="col-md-12 mb-15">
              <img src="images/success-icon.png" width="55px;" class="mt-15">
              <h3 class="mt-15">Successfully</h3>
              <p>Product Added Successfully</p>
             </div>
             
              <div class="form-group col-md-12">
                 <input type="submit" name="submit" class="button" value="Continue Shopping" data-dismiss="modal" aria-label="Close">
                 <a href="cart.html" class="button mt-15">View Cart</a>
              </div>
           </div>
        </div>
     </div>
  </div>
</div>
