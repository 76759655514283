
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LabtestRoutingModule } from './labtest-routing.module';
import { LabtestComponent } from './labtest.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';


@NgModule({
  declarations: [LabtestComponent],
  imports: [
    CommonModule,
    LabtestRoutingModule,
    MDBBootstrapModule.forRoot(),
    NgxSpinnerModule,
    SharedModule,
    NgxPaginationModule
  ]
})
export class LabtestModule { }
