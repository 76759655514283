import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { UtilService } from 'src/app/services/util.service';
import { CartService } from 'src/app/services/cart.service';
import { ModalDirective } from 'angular-bootstrap-md';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-labtest',
  templateUrl: './labtest.component.html',
  styleUrls: ['./labtest.component.scss']
})
export class LabtestComponent implements OnInit {
  @ViewChild('variantModal') public variantModal: ModalDirective;
  tabID = '';

  UsrLocation:any;

  newItem: boolean = false;
  page = 1;
  searchKeyword: any = '';

  sameCart: any[] = [];
  images: any[] = [];
  variantIndex: any = '';

  lists: any;
  carts: any[] = [];
  userCart: any[] = [];
  productName: any = '';

  sameProduct: boolean = false;
  removeProduct: boolean = false;
  LabTestCart: any[] = [];
  dummyLabTestCart: any[] = [];
  LabLocationlist:any[] = [];

  
  Labtest: any[]=[];
  dummyLabTest: any[] = [];
  LabtestisProfile: any[]=[];
  LabtestisPackage: any[]=[];
  dummy: any = Array(10);
  LabLocd:any='';

  TagGroup:any[]=[];
  Disorder:any[]=[];
  FilterId:any='';

  constructor(
    private router: Router,
    public api: ApiService,
    public cart: CartService,
    public util: UtilService,
    private route: ActivatedRoute, 
    private chMod: ChangeDetectorRef
  ) {
    this.getLabLocation();
    console.log('lablocation - ',localStorage.getItem('lablocation'));
    if(localStorage.getItem('lablocation')===null)
    {
      this.LocationConfirm(); 
    }
    else
    {
      this.LabLocd = localStorage.getItem('lablocation');
        if (this.route.snapshot.paramMap.get('filterid')) {
          this.FilterId = this.route.snapshot.paramMap.get('filterid');  
          this.getLabtestFilter(this.FilterId);
        }
        else
        {
          this.getLabtest();
        }
      
    }
    
    if (this.route.snapshot.paramMap.get('filterid')) {
      this.FilterId = this.route.snapshot.paramMap.get('testid'); 
      //this.getLabtestDetails(this.LabTestId); 
    }
    
     

  }

  ngOnInit(): void 
  { 
    
    this.getTagGroup();
    this.getDisorder();
  
  }

  changeLocation($event)
  {
    console.log($event);
    this.UsrLocation = $event;
    localStorage.setItem('lablocation',this.UsrLocation); 
    this.redirectTo('/labtest');
    /* this.router.navigate(['/labtest']);
    this.router.routeReuseStrategy.shouldReuseRoute = () => true; */
  }

  redirectTo(uri:string){
    this.router.navigateByUrl('/', {skipLocationChange: false}).then(()=>
    this.router.navigate([uri]));
 }

  getLabLocation() {
    this.api.get('lablocation/getlablocation').then((data: any) => {
      console.log(data);
      if (data && data.status === 200 && data.data.length) {
        this.LabLocationlist = data.data; 
      }
    }, error => {
      console.log(error);
      this.util.errorMessage(this.util.translate('something went wrong'));
    }).catch((error: any) => {
      console.log(error);
      this.util.errorMessage(this.util.translate('something went wrong'));
    });
  }

  goToLabtestDetail(item) {
    console.log(item);
    const param: NavigationExtras = {
      queryParams: {
        id: item.id,
        title: item.title.replace(/\s+/g, '-').toLowerCase()
      }
    }
    this.router.navigate(['/Labtest-detail'], param);
  }

  testDetails(item) {
    console.log(item);
    const param: NavigationExtras = {
      queryParams: {
        id: item.id 
      }
    }
    this.router.navigate(['/testdetails'], param);
  }


  onSearchChange(event) {
    console.log(event);
    if (event !== '') {
      this.Labtest = this.dummyLabTest.filter((ele: any) => {
        return ele.name.toLowerCase().includes(event.toLowerCase()); 
      });
      this.LabTestCart = this.Labtest;
    } else {
      this.Labtest = this.dummyLabTest;
      this.LabTestCart = this.Labtest;
    }
    
  }

  getTagGroup() 
  {
    this.api.get('taggroup').then((data: any) => {
      console.log(data); 
      if (data && data.status === 200 && data.data.length) {
        this.TagGroup = data.data;  
        this.TagGroup = data.data.filter(x => x.status === '1');   
      }
    }, error => {
      console.log(error);
      this.dummy = [];
      this.util.errorMessage(this.util.translate('Something went wrong'));
    }).catch((error: any) => {
      console.log(error);
      this.dummy = [];
      this.util.errorMessage(this.util.translate('Something went wrong'));
    });
  }
  
  getDisorder() {
    this.api.get('disorder/getDisorder').then((data: any) => {
      console.log(data);
      if (data && data.status === 200 && data.data.length) {
        this.Disorder = data.data;
        this.DisplayDisorder();
        //this.Disorder = data.data.filter(x => x.status === '1');
      }
    }, error => {
      console.log(error);
      this.util.errorMessage(this.util.translate('something went wrong'));
    }).catch((error: any) => {
      console.log(error);
      this.util.errorMessage(this.util.translate('something went wrong'));
    });
  }


  DisplayDisorder()
  {
    setTimeout(() => {
      
      // Life cycle slider
    $('.lifecycle_Slider').owlCarousel({
      loop:true,
      nav:true,
      autoplay:true,
      autoplayTimeout:4000,
      dots: false,
      lazyLoad: true,
      smartSpeed:3000,
      responsive:{
          0:{
              items:2
          },
          600:{
              items:2
          },
          1000:{
              items:2
          }
      }
    });

    }, 500);

    
  }

  getLabtestFilter(FilterId) 
  { 
    console.log('FilterId - '+FilterId);
    const param = {
      locationId: localStorage.getItem('lablocation'),
      filters: FilterId
    };

    this.api.post_private('labtest/getByLocationFilter',param).then((data: any) => {
      console.log(data);
      this.dummy = [];
      if (data && data.status === 200 && data.data.length) {
        this.Labtest = data.data.filter(x => x.name !== ''); 
        this.dummyLabTest = data.data;  
        this.LabtestisProfile = data.data.filter(x => x.isProfile === 'true'); 
        this.LabtestisPackage = data.data.filter(x => x.isPackage === 'true');

        data.data.forEach(element => {
          if (this.cart.itemId.includes(element.id)) {
            const index = this.cart.cart.filter(x => x.id === element.id);

            console.log('->index->', index);
            if (index && index.length) {
              element['quantiy'] = index[0].quantiy;
              element['selectedItem'] = index[0].selectedItem;
            } else {
              element['quantiy'] = 0;
              element['selectedItem'] = [];
            }
          } else {
            element['quantiy'] = 0;
            element['selectedItem'] = [];
          }
        });

        this.LabTestCart = data.data;
        this.dummyLabTestCart = data.data;
        this.chMod.detectChanges();

      }
    }, error => {
      console.log(error);
      this.dummy = [];
      this.util.errorMessage(this.util.translate('Something went wrong'));
    }).catch((error: any) => {
      console.log(error);
      this.dummy = [];
      this.util.errorMessage(this.util.translate('Something went wrong'));
    });
  }


  getLabtest() 
  {

    
    const param = {
      locationId: localStorage.getItem('lablocation'),

    };

    this.api.post_private('labtest/getByLocation',param).then((data: any) => {
      console.log(data);
      this.dummy = [];
      if (data && data.status === 200 && data.data.length) {
        this.Labtest = data.data.filter(x => x.isPackage === 'true').filter(x => x.name !== '') 
        this.dummyLabTest = data.data;  
        this.LabtestisProfile = data.data.filter(x => x.isProfile === 'true'); 
        this.LabtestisPackage = data.data.filter(x => x.isPackage === 'true');
        const Packages = document.getElementById('Packages',) as HTMLInputElement | null;
        Packages.checked = true;

        data.data.forEach(element => {
          if (this.cart.itemId.includes(element.id)) {
            const index = this.cart.cart.filter(x => x.id === element.id); 
            console.log('->index->', index);
            if (index && index.length) {
              element['quantiy'] = index[0].quantiy;
              element['selectedItem'] = index[0].selectedItem;
            } else {
              element['quantiy'] = 0;
              element['selectedItem'] = [];
            }
          } else {
            element['quantiy'] = 0;
            element['selectedItem'] = [];
          }
        });

        this.LabTestCart = this.Labtest; //data.data;
        this.dummyLabTestCart = data.data;
        this.chMod.detectChanges();

      }
    }, error => {
      console.log(error);
      this.dummy = [];
      this.util.errorMessage(this.util.translate('Something went wrong'));
    }).catch((error: any) => {
      console.log(error);
      this.dummy = [];
      this.util.errorMessage(this.util.translate('Something went wrong'));
    });
  }

  getContent(item) {
    return (item.test_name.length > 2) ? item.test_name.slice(0, 250) + '...' : item.test_name;
  }


  getDate(item) {
    return moment(item).format('DD');
  }

  getMonth(item) {
    return moment(item).format('MMM');
  }

  getURLTxt($scope)
  {
    return $scope.replace(',','').replace(/(\(.*\))/g, '').replace(' ' ,'-').replace('/','');
    
  }

  addQSame(index) {
    this.sameCart[index].total = this.sameCart[index].total + 1;
  }

  async presentAlertConfirm() {
    console.log('present alert to ask!');
    Swal.fire({
      title: this.util.translate('Warning'),
      text: this.util.translate(`you already have item's in cart!`),
      icon: 'error',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: this.util.translate('Clear cart'),
      backdrop: false,
      background: 'white'
    }).then(status => {
      if (status && status.value) {
        console.log('clear');
        this.cart.clearCart();
      }
    });

  }

  async LocationConfirm() {
    console.log('LocationConfirm alert to ask!');
     
    Swal.fire({
      title: this.util.translate('Location'),
      text: this.util.translate(`Select Your City`),
      input: "select",
      inputOptions: {
        'l1': 'Delhi',
        'l2': 'Haridwar'
      },
      icon: 'info',
      //showCancelButton: true,
      showConfirmButton: true, 
      showLoaderOnConfirm: true,
      confirmButtonText: this.util.translate('Select'),
      //cancelButtonText: "Cancel",
       
      allowOutsideClick: () => !Swal.isLoading(),
       
      backdrop: false,
      background: 'white'
    }).then((result) => {
      if (result) 
        {
              this.UsrLocation = result.value;
              localStorage.setItem('lablocation',this.UsrLocation); 
              this.LabLocd = localStorage.getItem('lablocation');
              console.log('clear',localStorage.getItem('lablocation'));
              this.getLabtest();
              /* Swal.fire({
                title: `${result.value}`,
                
              }) */
              this.router.navigate(['/labtest']);
              //this.cart.clearCart();
        }
    });

  }

  

  async ItemAddedAlerts() {
    console.log('present alert to ask!');
    Swal.fire({
      title: this.util.translate('Successfully'),
      text: this.util.translate(`Test added successfully in cart!`),
      icon: 'success',
      showCancelButton: true,
      showConfirmButton: true, 
      confirmButtonText: this.util.translate('View Cart'),
      cancelButtonText: "Continue Shopping",
       
      backdrop: false,
      background: 'white'
    }).then(status => {
      if (status && status.value) {
        console.log('clear');
        this.router.navigate(['/show-cart']);
        //this.cart.clearCart();
      }
    });

  }


  
  filterTest(type)
  {
     const All = document.getElementById('All',) as HTMLInputElement | null;
     const Test = document.getElementById('Test',) as HTMLInputElement | null;
     const Packages = document.getElementById('Packages',) as HTMLInputElement | null;
     const Profile = document.getElementById('Profile',) as HTMLInputElement | null;

        

    console.log('present alert to ask!'+type);
    if(type==='All')
    {
      Test.checked = false;
      Packages.checked = false;
      Profile.checked = false; 
      this.Labtest = this.dummyLabTest; 
      this.LabTestCart = this.Labtest;
    }
    if(type==='Test')
    {
      All.checked = false;
      Packages.checked = false;
      Profile.checked = false; 
      this.Labtest = this.dummyLabTest.filter(x => x.isPackage === 'false').filter(x => x.isProfile === 'false');
      this.LabTestCart = this.Labtest; 
    }
    else if(type==='Packages')
    {
      Test.checked = false;
      All.checked = false;
      Profile.checked = false; 
      this.Labtest = this.dummyLabTest.filter(x => x.isPackage === 'true'); 
      this.LabTestCart = this.Labtest;
    }
    else if(type==='Profile')
    { 
      Test.checked = false;
      Packages.checked = false;
      All.checked = false; 
      this.Labtest = this.dummyLabTest.filter(x => x.isProfile === 'true');  
      this.LabTestCart = this.Labtest;
    }

    
    

  }

  addToCart(id) {

    this.variantIndex= id;
    /*
      new
      sameChoice
      newCustom
      remove
    */

    //const addedSize = this.carts.filter(x => x.type === 'size');
    //console.log(addedSize);
    let role;
    
    if (this.carts.length && !this.userCart.length) {
      role = 'new';
    }
    if (this.carts.length && this.userCart.length) {
      role = 'new';
    }
    if (!this.carts.length) {
      role = 'dismissed';
    }
    if (this.newItem) {
      role = 'newCustom';
    }
    console.log('role', role, this.carts);

    
    if (role === 'new') {
      this.LabTestCart[this.variantIndex].quantiy = 1;
      const carts = {
        item: this.carts,
        total: 1
      };
      this.LabTestCart[this.variantIndex].selectedItem.push(carts);
      console.log('id===>?>>', this.LabTestCart[this.variantIndex].id);
      this.cart.addVariations(this.LabTestCart[this.variantIndex], carts, role);
      this.cart.calcuate();
    }
    if (role === 'newCustom') {
      const carts = {
        item: this.carts,
        total: 1
      };
      this.LabTestCart[this.variantIndex].selectedItem.push(carts);
      this.LabTestCart[this.variantIndex].quantiy = this.LabTestCart[this.variantIndex].quantiy + 1;
      this.cart.addVariations(this.LabTestCart[this.variantIndex], carts, 'newCustom');
      this.cart.calcuate();
    }
    if (role === 'dismissed') {
      this.LabTestCart[this.variantIndex].quantiy = 1;
      const carts = {
        item: this.carts,
        total: 1
      };
      this.LabTestCart[this.variantIndex].selectedItem.push(carts);
      console.log('id===>?>>', this.LabTestCart[this.variantIndex].id);
      this.cart.addVariations(this.LabTestCart[this.variantIndex], carts, 'new');

      // console.log('none choice');
      // this.LabTestCart[this.variantIndex].quantiy = this.LabTestCart[this.variantIndex].quantiy + 1;
      // this.cart.addQuantity(this.LabTestCart[this.variantIndex].quantiy, this.LabTestCart[this.variantIndex].id);
      // this.chMod.detectChanges();
    }
    this.variantModal.hide();
  }

  add(index) {
    const uid = localStorage.getItem('uid');
    console.log('uid', localStorage.getItem('uid'));
    console.log('Item - ', index + "  Details" + this.LabTestCart[index]);
    console.log('Item End ');

    document.getElementById("a"+index).style.display='none';
    document.getElementById("r"+index).style.display='block';
    //if (uid && uid != null && uid !== 'null') {
          this.LabTestCart[index].quantiy = 1;
          this.cart.addItem(this.LabTestCart[index]);
          this.ItemAddedAlerts(); 
          this.chMod.detectChanges();
          
      /* if (this.cart.cart.length === 0) {
        console.log('cart is empty');
        if (this.LabTestCart[index].variations && this.LabTestCart[index].variations.length) {
          console.log('open modal');
          this.openVariations(index);
        } else {
          this.LabTestCart[index].quantiy = 1;
          this.cart.addItem(this.LabTestCart[index]);
        }
      } else {
        console.log('cart is full');
        this.presentAlertConfirm(); 
      } */
      
    /* } else {
      // this.router.navigate(['/login']);
      // this.loginModal.show();
      this.util.onLoginPop();
    } */
  }

  removeQ(index) {

    if (index < 0) {
      console.log('negative items');
      this.cart.cart = [];
      localStorage.removeItem('userCart');
      window.location.reload();
    }
     
    this.LabTestCart[index].quantiy = 0;
    this.cart.removeItem(this.LabTestCart[index].id);
    this.chMod.detectChanges();
    document.getElementById("a"+index).style.display='block';
    document.getElementById("r"+index).style.display='none';
     
  }

  isInCart(index)
  {
    if(this.LabTestCart[index].quantiy>0)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  async openVariations(index) {
    console.log('open variantions///', this.LabTestCart[index]);
    this.variantIndex = null;
    this.lists = [];
    this.productName = ''; 
    this.sameProduct = false;
    this.sameCart = [];
    this.userCart = [];
    ////
    this.carts = [];
    this.variantIndex = index;
    this.lists = this.LabTestCart[index].variations;
    this.productName = this.LabTestCart[index].name;
    console.log(this.productName, this.LabTestCart[index]);
    const userCart = localStorage.getItem('userCart'); 
    console.log('usercart---->', userCart);
    if (userCart && userCart !== 'null' && userCart !== undefined && userCart !== 'undefined') {
      this.userCart = JSON.parse(userCart);
      console.log('===>>', this.userCart);
      const sameItem = this.userCart.filter(x => x.id === this.LabTestCart[index].id);
      console.log('sameItem', sameItem);
      if (sameItem.length > 0) {
        this.sameProduct = true;
        this.sameCart = sameItem[0].selectedItem;
        console.log('=??==>asdasd-->>>asd>>>>', this.sameCart);
      }
    } else {
      this.userCart = [];
    }
    console.log(this.sameProduct);
    this.variantModal.show();
  }




}
